<template>
  <div class="admin-account">
    <div class="admin-box">
      <p>修改密码</p>
         <div class="admin-box-login">
           <span>新密码：</span>
           <div class="admin-box-login-div">
              <input :type="mimaS" placeholder="请输入您的密码"  v-model="passwordSx">
              <i @click="eyeS" class="gai"><img :src="suoS?require('../../../assets/img/bi.png'):require('../../../assets/img/zheng.png')" alt=""></i>
            </div>
         </div>
         <div class="admin-box-login">
            <span>再次确认：</span>
            <div class="admin-box-login-div">
              <input :type="mima" placeholder="请输入您的密码"  v-model="passwordS">
              <i @click="eyeX" class="gai"><img :src="suo?require('../../../assets/img/bi.png'):require('../../../assets/img/zheng.png')" alt=""></i>
            </div>
         </div>
         <div class="admin-button">
           <div class="admin-button-box">
              <a @click="register()">确 定</a>
           </div>
         </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mima: 'password',
      mimaS: 'password',
      password: '',
      passwordS: '',
      passwordSx: '',
      suo: true,
      suoS: true
    }
  },
  methods: {
    // 密码显示隐藏
    eyeX() {
      this.suo = !this.suo
      if (this.suo) {
        this.mima = 'password'
      } else {
        this.mima = 'text'
      }
    },
    eyeS() {
      this.suoS = !this.suoS
      if (this.suoS) {
        this.mimaS = 'password'
      } else {
        this.mimaS = 'text'
      }
    },
    // 确定按钮
    register() {
      if (this.passwordSx !== '' && this.passwordS !== '') {
        if (this.passwordSx !== this.passwordS) {
          this.$message({
            message: '密码不一致',
            type: 'error'
          })
        } else {
          this.$message({
            message: '修改成功',
            type: 'success'
          })
        }
      } else {
        this.$message({
          message: '新旧密码不能为空',
          type: 'error'
        })
      }
    }
  }
}
</script>

<style scoed lang="scss">
.el-main{
  // 
    background: #F6F6FA;
}

.admin-account{
  width: 100%;
  background: #fff;
  padding: 84px 0 280px 0;
  -moz-box-shadow:0px 0px 10px #e8e8eb; -webkit-box-shadow:0px 0px 10px #e8e8eb; box-shadow:0px 0px 10px #e8e8eb;
  border-radius: 15px;

  .admin-button{
    width: 600px;
    margin: 0 auto;
    .admin-button-box{
      a{
        width: 450px;
        height: 50px;
        line-height: 50px;
        background: linear-gradient(200deg, #FC4353, #FF5E41);
        border-radius: 10px;
        font-size: 20px;
        text-align: center;
        margin: 0 auto 0 124px;
        display: block;
        color: #fff;
      }
    }
  }

  .admin-box{
    & > p{
      font-size: 24px;
      margin-bottom: 100px;
      text-align: center;
      color: #999999;
    }
    input{
      padding-left: 15px;
      box-sizing: border-box;
    }
    .admin-box-login{
      position: relative;
      display: flex;
      width: 600px;
      margin: 0 auto;
      .admin-box-login-div{
        width: 460px;
        margin: 0 auto;
      }
      & > span{
        width: 101px;
        font-size: 18px;
        display: block;
        text-align: right;
        margin-top: 14px;
        color: #333;
      }
      i{
        position: absolute;
        right: 31px;
        top: 8px;
      }
    }
  }
}
</style>
